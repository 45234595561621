
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import { Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const base = namespace("base");
const fission = namespace("fission");
const management = namespace("management");

@Component({
  components: { FileUpload }
})
@Table("loadListData")
export default class CampaignAnalysisFission extends Vue {
  @base.Action getDictionaryList;
  @fission.Action statisticCampaign;
  @fission.Action queryFissionCampaignDetail;
  @fission.Action queryFissionCampaign;
  @management.Action getStoreList;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  get breadData() {
    return [
      { name: t("v210801.platform-fission-activity"), path: "/management/fission/fission-campagin" },
      {
        name: t("v210801.activity-data-analysis")
      }
    ];
  }
  searchForm: any = {
    customerName: "",
    customerMobile: "",
    detailStatus: ""
  };
  storeList = [];
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  get searchList() {
    return [
      {
        label: t("v210831.store-name"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: t("v210801.query-mode"),
        type: "select",
        selectList: this.relevancedetailStatusList,
        value: "",
        prop: "inquiryMode",
        placeholder: t("v210801.query-by-customer")
      },
      {
        label: t("v210831.full-name"),
        type: "input",
        value: "",
        prop: "name"
      },
      {
        label: t("finance.mobile"),
        type: "input",
        value: "",
        prop: "mobile"
      },
      {
        label: t("marketing.card-num"),
        type: "input",
        value: "",
        prop: "prizeCode"
      }
    ];
  }
  campaginPrevPathEnum: any = {
    lottery: "/marketing/campaign/lottery",
    collect: "/marketing/campaign/collect-customer"
  };
  campaignTypeEnum: any = {
    lottery: t("v210801.lucky-draw"),
    collect: t("v210801.customer-accumulation-activities")
  };
  get id() {
    return this.$route.params.id;
  }
  get campaignType() {
    return this.$route.query.campaignType as string;
  }
  created() {
    this.init();
  }
  replace(string: string) {
    let arr = string.split(",").filter(v => v !== "");
    return arr;
    // return string.replace(/,/g, "");
  }
  campaignCustomerList: any = [];
  relevancedetailStatusList: any = [];
  statisticData: any = [{}, {}, {}];
  get lookToReceiveRate() {
    try {
      if (Number(this.statisticData[0].fissionCount) && Number(this.statisticData[1].fissionCount)) {
        return ((this.statisticData[1].fissionCount / this.statisticData[0].fissionCount) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get ReceiveToUsedRate() {
    try {
      if (Number(this.statisticData[1].fissionCount) && Number(this.statisticData[2].fissionCount)) {
        return ((this.statisticData[2].fissionCount / this.statisticData[1].fissionCount) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get lookToUsedRate() {
    try {
      if (Number(this.statisticData[0].fissionCount) && Number(this.statisticData[2].fissionCount)) {
        return ((this.statisticData[2].fissionCount / this.statisticData[0].fissionCount) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  campaignData: any = {};
  init() {
    this.loadListData();
    this.getDictionaryList("fission_inquiry_mode").then(data => {
      this.relevancedetailStatusList = data;
    });
    this.statisticCampaign({
      campaignCode: this.id
    }).then(data => {
      this.statisticData = data.data.statisticArray;
    });
    // this.queryFissionCampaign({ campaignCode: this.id }).then(data => {
    //   this.campaignData = data.data;
    // });
    this.queryFissionCampaign({ campaignCode: this.id }).then(data => {
      console.log("[ data ]", data);
      this.campaignData = data.data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryFissionCampaignDetail({
      campaignCode: this.id,
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.campaignCustomerList = data.data.list;
      return data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
}
